@import "~@/erp/styles/variables/variables.scss";























































































































































.footer {
  .el-button+.el-button {
    margin-left: 30px;
  }
}

.ml15 {
  margin-left: 15px;
}
