@import "~@/erp/styles/variables/variables.scss";



























.inspection-result-wrapper {
  display: inline-block;
  text-align: right;
  font-size: 12px;
  font-weight: 600;
  .label {
    color: #606266;
    margin-right: 4px;
  }
  .count {
    color: #606266;
    margin-right: 10px;
  }
}
